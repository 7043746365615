import { Grid } from "components/UI/Grid/Grid"
import styled from "styled-components"
import { mq, typography } from "styles"
import { $BgColor } from "typings/modules"

export const Container = styled(Grid)<$BgColor>`
  background: ${({ $bgColor }) => $bgColor};
  display: grid;
  align-items: center;
  padding: 32px 0 64px;
  ${mq.minWidth("md")} {
    padding: 48px 0 96px;
  }
  ${mq.minWidth("lg")} {
    padding: 64px 0;
  }
`

export const Header = styled.div`
  color: ${({ theme }) => theme.localTheme.text.primary.opacityHex};
  margin-bottom: 24px;
  ${mq.minWidth("md")} {
    margin-bottom: 48px;
  }
`

export const Footer = styled.div`
  color: ${({ theme }) => theme.localTheme.text.primary.opacityHex};
  text-align: center;
  margin-top: 16px;
  ${mq.minWidth("md")} {
    margin-top: 24px;
  }
  p {
    ${typography.bodySmall};
  }
`

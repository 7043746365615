import React, { useState } from "react"
import * as Yup from "yup"
import { Formik } from "formik"
import { FormField } from "./FormField"
import { Button } from "components/UI/Button/Button"
import { Form, Notice } from "./FormSignup.styles"
import {
  Maybe,
  SanityFiftyFiftyForm,
  SanityFiftyFiftyFormField,
} from "typings/graphql"
import { PortableText } from "components/UI/PortableText/PortableText"
import { Config } from "config/brands/index"
import { ModeType } from "typings/modules"

const fieldTypeValidations = {
  text: () => Yup.string(),
  textarea: () => Yup.string(),
  email: () => Yup.string().email("Please provide a valid email"),
  phone: () =>
    Yup.string().matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      "Please provide a valid phone number"
    ),
}

type FieldTypes = keyof typeof fieldTypeValidations
type SanityField = SanityFiftyFiftyFormField

const schemaBuilder = (fields: Maybe<SanityField>[]) => {
  const mappedFields = fields.map(field => {
    const key = field.segmentKey
    const fieldType = field?.type as FieldTypes
    const validation = fieldTypeValidations[fieldType]()
    const value = field?.required
      ? validation.required(`${field?.placeholder} is required`)
      : validation
    return { [key]: value }
  })
  const schemaFields = mappedFields.reduce(
    (acc, field) => ({ ...acc, ...field }),
    {}
  )
  return Yup.object().shape(schemaFields)
}

export const FormSignup = (props: SanityFiftyFiftyForm) => {
  const fields = props.inputs
  const [success, setSuccess] = useState(false)
  const initKeys = fields.map(field => field.segmentKey)
  const initValues: { [index: string]: string } = initKeys.reduce(
    (acc, key) => ({ ...acc, [key]: "" }),
    {}
  )
  const validSchema = schemaBuilder(fields)
  const successText = props._rawSuccessText
  const mode = props.mode as ModeType

  return (
    <Formik
      initialValues={initValues}
      validationSchema={validSchema}
      onSubmit={values => {
        const emailField = fields.find(field => field?.type === "email")
        const nonEmailValues = Object.keys(values)
          .filter(key => key !== emailField.segmentKey)
          .reduce((acc, obj) => ({ ...acc, [obj]: values[obj] }), {})
        const email = values[emailField.segmentKey]

        const trackEmailRegistered = () => {
          window.analytics.track(props.trackingName || "", {
            signup_source: props.trackingSignupSource,
            category: props.trackingCategory,
            label: props.trackingLabel,
            email,
            ...nonEmailValues,
          })
        }

        if (email) {
          const identityAffiliation = Config.shopify?.id
          const hashedEmail = window.btoa(email)

          // using callback so user is identified before tracking event
          window.analytics.identify(
            hashedEmail,
            {
              identityAffiliation,
              email,
            },
            trackEmailRegistered
          )
        } else {
          trackEmailRegistered()
        }

        setSuccess(true)
      }}
    >
      <Form noValidate>
        {fields.map(field => (
          <FormField
            key={field?.segmentKey}
            placeholder={field?.placeholder || ""}
            name={field?.segmentKey}
            type={field?.type}
            label={field?.placeholder}
            required={field?.required}
            mode={mode}
          />
        ))}
        <Button disabled={success} type="submit" fullWidth>
          {props.ctaText}
        </Button>
        {success && (
          <Notice>
            <PortableText blocks={successText} $mode={mode} />
          </Notice>
        )}
      </Form>
    </Formik>
  )
}

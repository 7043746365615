import { Form as FormikForm } from "formik"
import styled from "styled-components"
import { typography } from "styles"

export const Form = styled(FormikForm)`
  position: relative;
  display: grid;
  margin: 0;
`

export const Notice = styled.div`
  ${typography.bodySmall};
  color: ${({ theme }) => theme.localTheme.text?.formSuccess?.opacityHex};
  padding-top: 8px;
`

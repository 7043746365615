import React, { useContext } from "react"
import { ThemeContext, ThemeProvider } from "styled-components"
import { SanityFiftyFiftyForm } from "typings/graphql"
import { Container, Header, Footer } from "./Form.styles"
import { PortableText } from "components/UI/PortableText/PortableText"
import { ModeType } from "typings/modules"
import { FormSignup } from "./FormSignup"

type Props = {
  item: SanityFiftyFiftyForm
}

export const Form = ({ item }: Props) => {
  const themeContext = useContext(ThemeContext)
  const header = item._rawHeader
  const footer = item._rawFooter
  const bgColor = item.bgColor.opacityHex
  const mode = item.mode as ModeType
  return (
    <ThemeProvider theme={{ localTheme: themeContext[mode] }}>
      <Container $bgColor={bgColor} base="1 [10] 1" md="2 [12] 2" lg="2 [8] 2">
        <div>
          {header && (
            <Header>
              <PortableText blocks={header} $mode={mode} />
            </Header>
          )}
          <FormSignup {...item} />
          {footer && (
            <Footer>
              <PortableText blocks={footer} $mode={mode} />
            </Footer>
          )}
        </div>
      </Container>
    </ThemeProvider>
  )
}

export default Form

import styled, { css } from "styled-components"
import { mq, typography } from "styles"
import { $Mode } from "typings/modules"

export const Label = styled.label`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`

export const Fieldset = styled.div`
  margin-bottom: 16px;
  ${mq.minWidth("md")} {
    margin-bottom: 24px;
  }
  position: relative;
`

export const inputStyles = css<$Mode>`
  ${typography.body};
  color: ${({ theme, $mode }) => theme[$mode]?.text?.formActive?.opacityHex};
  background-color: ${({ theme, $mode }) =>
    theme[$mode]?.ui?.formField?.opacityHex};
  padding: 0 20px;
  border: 0;
  outline: 0;
  appearance: none;
  width: 100%;
  ::placeholder {
    font: inherit;
    opacity: 1;
    color: ${({ theme, $mode }) =>
      theme[$mode]?.text?.formInactive?.opacityHex};
  }
`

export const TextInput = styled.input`
  ${inputStyles};
  padding: 0 20px;
  height: 56px;
  ${mq.minWidth("md")} {
    height: 64px;
  }
`

export const Textarea = styled.textarea`
  ${inputStyles};
  padding: 16px 20px;
  height: 128px;
`

export const Error = styled.div`
  ${typography.bodySmall};
  color: ${({ theme }) => theme.dark?.text?.formError?.opacityHex};
  padding-top: 4px;
  text-align: left;
  position: absolute;
  font-size: 10px !important;
  bottom: -15px;
  ${mq.minWidth("md")} {
    bottom: -18px;
  }
`

import React from "react"
import { useField } from "formik"
import { Label, TextInput, Textarea, Fieldset, Error } from "./FormField.styles"
import { ModeType } from "typings/modules"

type Props = {
  label: string
  placeholder: string
  type: string
  name: string
  required: boolean
  mode: ModeType
}

export const FormField = ({ label, ...props }: Props) => {
  const Input = props.type === "textarea" ? Textarea : TextInput
  const Tag = Input as React.ComponentType<any>
  const [field, meta] = useField(props)
  return (
    <Fieldset>
      <Label htmlFor={props.name}>{props.placeholder}</Label>
      <Tag
        {...field}
        id={props.name}
        placeholder={props.placeholder}
        $mode={props.mode}
        autoComplete="off"
        spellCheck="false"
      />
      {meta.touched && meta.error && <Error>{meta.error}</Error>}
    </Fieldset>
  )
}
